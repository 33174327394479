import React, { FunctionComponent } from "react"
import Layout from "../components/layout"
import resume from "../assets/pdf/john-medina-resume.pdf"
import Card from "../components/card"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { faBriefcase } from "@fortawesome/free-solid-svg-icons"

const query = graphql`
    query GetImages {
        headshot: file(relativePath: { eq: "john-medina.png" }) {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        tools: allFile(filter: { relativeDirectory: { eq: "tools" } }) {
            edges {
                node {
                    childImageSharp {
                        fixed(width: 200) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
        # altium: file(relativePath: { eq: "altium.jpg" }) {
        #     childImageSharp {
        #         fixed(height: 75) {
        #             ...GatsbyImageSharpFixed
        #         }
        #     }
        # }
    }
`

type ToolProps = {
    image: any
    caption: string
    children?: React.ReactNode
}

const ToolComponent: FunctionComponent<ToolProps> = ({
    image,
    caption,
    children,
}: ToolProps) => (
    <div className="md:flex md:items-center">
        <div className="flex justify-center md:block md:mr-4 mb-2">
            <Img fixed={image} />
        </div>
        <div>
            <p>{caption}</p>
            {children}
        </div>
    </div>
)

const AboutUsPage = () => {
    const data = useStaticQuery(query)
    console.log(data)
    const imageData = data.headshot.childImageSharp.fluid
    const toolImages = data.tools.edges.map(
        (tool) => tool.node.childImageSharp.fixed
    )
    const toolDescriptions = [
        `
        Altium Designer System Engineering (SE) is a 
        fully-featured editor for schematics that includes 
        powerful collaboration capabilities and a rich set 
        of schematic capture tools to quickly create, edit, 
        simulate, and document schematics.
        `,
        `
        Mentor Graphics® is a technology leader in electronic 
        design automation (EDA), providing software and hardware 
        design solutions that enable companies to develop better 
        electronic products faster and more cost-effectively. 
        `,
        `
        Cadence is a leading EDA and Intelligent System Design 
        provider delivering hardware, software, and IP for electronic design.
        `
    ]
    const toolExtras = [
        <a href="https://resources.altium.com/p/pcb-design-process-concepts">
            https://resources.altium.com/p/pcb-design-process-concepts
        </a>,
    ]
    return (
        <Layout title="About Us">
            <div className="mx-auto container px-4">
                <h1>About Us</h1>
                <hr className="mb-4" />
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:gap-8 m-5">
                    <div className="lg:col-span-1">
                        <div className="m-4">
                            <Img
                                className="rounded-full"
                                fluid={imageData}
                                alt="john medina headshot"
                            />
                        </div>
                    </div>
                    <div className="lg:col-span-3">
                        <p>
                            With over 25 years of extensive experience in PCB
                            design, training and analysis using state of the art
                            tool sets, including Boardstation RE/XE, Expedition,
                            CES (Constraint Editor System), IOD IO Designer,
                            Hyperlnyx, Interconnectix(ICX), Power SI and Allegro
                            v16.2 suite including CMS (Constraint Management
                            System) I currently own and operate High Speed
                            Design Services, LLC.
                        </p>
                        <p>
                            My background is completing complex PCB designs, and
                            delivering training of PCB design tools and
                            methodologies. I utilize my background to complete
                            complex, HDI, high speed, RF, mixed technology
                            designs, while combining signal integrity
                            verification and analysis.
                        </p>

                        <p>
                            I have worked at Northrop Grumman for 7 years at
                            Radio Systems, Space Park, Woodland Hills delivering
                            flight designs for Aerospace and Military
                            applications. I worked with San Jose and Sacramento
                            NGC sites on Expedition Library and Tool flow
                            migration efforts
                        </p>

                        <p>
                            I recently completed a design for NGC Baltimore as a
                            sub-contractor of FCS
                        </p>

                        <p>
                            I previously worked for HP, Agilent, Nokia , Apple
                            and Cisco as a working technical manger and built
                            teams that could deliver complex HDI CPU designs. I
                            have implemented a PCB Process and tool flow which
                            includes SI Tools for verification.
                        </p>

                        <p>
                            I have designed extremely dense 8 and 16Gig DDR3
                            Dimm designs operating at 1333 MHz using large pin
                            count .5mm pitch bags. These designs included
                            establishing high speed rule constraints through the
                            extensive use of Allegro layout with CMS and
                            Expedition/CES and utilizing SI tools to verify
                            timing constraints, review eye diagrams and noise
                            level requirements.
                        </p>
                        <p>
                            <a href={resume} target="_blank">
                                Link to Resume
                            </a>
                        </p>
                    </div>
                </div>
                <Card title="Tools We Use" icon={faBriefcase}>
                    {toolDescriptions.map((toolDescription, i) => (
                        <ToolComponent
                            image={toolImages[i]}
                            caption={toolDescription}
                        >
                            {toolExtras[i]}
                        </ToolComponent>
                    ))}
                </Card>
            </div>
        </Layout>
    )
}

export default AboutUsPage
